import React from 'react'
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Section, BreadCrumb, MarkdownContent, MarkdownContentWrapper, MarkdownContentWrapperInner, MarkdownContentTitle, ComingSoon } from '../components/Section'
import PrimaryLinkButton from '../components/Button/PrimaryLinkButton'
import { Link } from "gatsby"
import styled from 'styled-components'
import { StaticImage } from 'gatsby-plugin-image'
import BreakpointDown from '../components/Media/BreakpointDown'


const GroceryTitle = styled.h1`

`
const GroceryRow = styled.div`
  display:flex;
  margin-left:-15px;
  margin-right:-15px;
  flex-wrap:wrap;
  justify-content:space-between;
  margin-top:20px;
`
const GroceryColumn = styled.div`
 flex:0 0 50%;
 width:50%;
 padding-left:15px;
 padding-right:15px;
 ${BreakpointDown.xl`
  flex:0 0 100%;
  width:100%; 
 `}
 .gatsby-image-wrapper{ width:100%;}
 &:first-child{
   padding-right:60px;
   ${BreakpointDown.xl`
      flex:0 0 100%;
      width:100%;
      padding-right:15px;
    `}
 }
 &:last-child{
   padding-top:40px;
   ${BreakpointDown.md`
     padding-top:0px;
   `}
 }
`


// GroceryIndustry
const SectionBoxShadow = styled(Section)`
  box-shadow: 0px 20px 85px #00000033;
  z-index:2;
`
const GroceryIndustryTitle = styled.h2`
  max-width: 768px;
  text-align:center;
  margin-left:auto;
  margin-right:auto;
`

const GroceryIndustryList = styled.div`
 display:flex;
 flex-wrap:wrap;
 margin-top:60px;
 border-bottom:1px solid #CCCCCC;
 padding-bottom:60px;
 ${BreakpointDown.xl`
   margin-top:30px;
   padding-bottom:30px;
 `}
 &:nth-child(odd){
   flex-direction:row-reverse;
   &:last-child{border-bottom:0; padding-bottom:0;}
   .industry-left{
  &:before{
    right:auto;
    margin-right:0;
    left: 0;
    top: 35px;
    margin-left: -60px;
  }
   }
   .industry-right{
    padding-right: 90px;
    padding-left:0;
    ${BreakpointDown.xl`
      padding-right: 0px;
    `}
   }
 }
`
const GroceryIndustryListLeft = styled.div`
flex:0 0 600px;
position:relative;
${BreakpointDown.xl`
 flex:0 0 100%;
`}
&:before{
  content:'';
  width:120px;
  height:4px;
  background:#000;
  position:absolute;
  right:0;
  top:35px;
  margin-right:-60px;
  z-index:1;
  ${BreakpointDown.xl`
     display:none;
  `}
}
`
const GroceryIndustryListRight = styled.div`
  flex:1;
  padding-left:90px;
  padding-top:20px;
  ${BreakpointDown.xl`
    padding-left: 0px;
  `}
    ${BreakpointDown.md`
     padding-top:10px;
  `}
`
const GroceryIndustryListRightTitle = styled.div`
${BreakpointDown.md`
  margin-bottom:0;
`}
`
const GroceryIndustryListRightContent = styled.div`
 p{
   font-style:normal;
 }
`
const TraingleShape = styled.div`
  width: 0;
  height: 0;
  display: block;
  border-top: 20px transparent solid;
  border-right: 20px transparent solid;
  border-left: 20px transparent solid;
  border-bottom: 20px #fff solid;
  position: absolute;
  left: 0;
  right: 0;
  bottom: -1px;
  margin-left: auto;
  margin-right: auto;
  z-index: 3;
`


function GroceryPage() {
  return (
    <Layout pageName="industry">
      <SEO
        title="Business Technology Solutions for Grocery and Food Outlets"
        description="WYN Technologies offers business technology solutions to the grocery and retail industry across the states. Get a quote for POS, digital signage, security camera and data management for your retail business."/>
      <Section pt="174px" pb="80px" bgColor="#F8F8F8" xpt="120px" xpb="60px" mpt="90px" mpb="40px">
        <TraingleShape />
        <div className="container">
          <BreadCrumb>
            <Link to="/">Home</Link><Link to="/#">Grocery</Link>
          </BreadCrumb>
          <GroceryRow>
            <GroceryColumn>
              <GroceryTitle>Business Technology Solutions for the Grocery Retail Industry</GroceryTitle>
              <p>Technology has come a long way in terms of making agriculture and food production both more effective and more efficient; the development of hydroponics is a good example. Technology advances have benefited the grocery retail industry in many ways, as well. Concepts ranging from Smart Carts, to checkout-free shopping in store, to online ordering and delivery continue to help reinvent the customer experience for grocery shoppers.</p>
              <p>But regardless of whether you operate a single independent grocery store or manage a chain of locations, there are many business technology solutions that can make your whole enterprise better for you, your employees, and your customers. And here’s the best news – <strong>WYN Technologies</strong> provides and delivers the essential technology solutions you really need!</p>
            </GroceryColumn>
            <GroceryColumn>
              <StaticImage
                src="../images/industry-1.jpg"
                quality={100}
                formats={["AUTO", "WEBP", "AVIF"]}
                alt="Brands We Trust"
                placeholder="blurred"
              />
            </GroceryColumn>
          </GroceryRow>
        </div>
      </Section>
      <SectionBoxShadow bgColor="#F8F8F8" pt="80px" pb="80px" xpt="60px" xpb="60px" mpt="40px" mpb="40px">
        <div className="container">
          <GroceryIndustryTitle className="h1">Some of the Services We Offer for Grocery Stores and Grocery-Related Businesses</GroceryIndustryTitle>
          <GroceryIndustryList>
            <GroceryIndustryListLeft className="industry-left">
              <StaticImage
                src="../images/placholder.jpg"
                quality={100}
                formats={["AUTO", "WEBP", "AVIF"]}
                alt="Industry"
                placeholder="blurred"
              />
            </GroceryIndustryListLeft>
            <GroceryIndustryListRight className="industry-right">
              <GroceryIndustryListRightTitle className="h3">Digital Signage Installation</GroceryIndustryListRightTitle>
              <GroceryIndustryListRightContent>
                <p>Digital signage has many good applications for grocery stores. Outdoor digital signs are a great visual for drawing folks to your business off the street. Indoor digital signage is a great way to make your special displays pop, and gives you some more creative options for merchandising and conveying important product information or selling points. And interactive touchscreen signs can be used to engage customers all over your store, from the fresh side to the center store aisles and endcaps. <strong>WYN Technologies</strong> can install digital signage for all of these applications, and more!</p>
                {/* <Link to="/"><PrimaryLinkButton text="Learn More"></PrimaryLinkButton></Link> */}
              </GroceryIndustryListRightContent>
            </GroceryIndustryListRight>
          </GroceryIndustryList>
          <GroceryIndustryList>
            <GroceryIndustryListLeft className="industry-left">
              <StaticImage
                src="../images/placholder.jpg"
                quality={100}
                formats={["AUTO", "WEBP", "AVIF"]}
                alt="Industry"
                placeholder="blurred"
              />
            </GroceryIndustryListLeft>
            <GroceryIndustryListRight className="industry-right">
              <GroceryIndustryListRightTitle className="h3">Drive-Up Grocery Service Technology Installations</GroceryIndustryListRightTitle>
              <GroceryIndustryListRightContent>
                <p>Speaking of grocery retail innovations, adding a drive-up or drive-thru lane can really boost the convenience factor for your customers. Outdoor digital signs, interactive touchscreens, and intercom systems are very helpful for facilitating a seamless grocery pickup experience for customers who’ve placed their orders ahead of time. Need some help designing the right drive-up grocery solution to meet your needs? <strong>WYN</strong> is at your service!</p>
                {/* <Link to="/"><PrimaryLinkButton text="Learn More"></PrimaryLinkButton></Link> */}
              </GroceryIndustryListRightContent>
            </GroceryIndustryListRight>
          </GroceryIndustryList>
          <GroceryIndustryList>
            <GroceryIndustryListLeft className="industry-left">
              <StaticImage
                src="../images/placholder.jpg"
                quality={100}
                formats={["AUTO", "WEBP", "AVIF"]}
                alt="Industry"
                placeholder="blurred"
              />
            </GroceryIndustryListLeft>
            <GroceryIndustryListRight className="industry-right">
              <GroceryIndustryListRightTitle className="h3">POS System Installations</GroceryIndustryListRightTitle>
              <GroceryIndustryListRightContent>
                <p>First impressions decide whether a customer will stay and shop with you, but last impressions decide whether a customer will ever come back. And when it comes to grocery shopping, the checkout experience is that all-important last impression. The right point-of-sale system can make all the difference in getting shoppers in and out efficiently, and also makes the experience more pleasant for employees and customers alike. <strong>WYN Technologies</strong> provides and installs top touchscreen POS registers and systems that can help your customer’s last impression to be as positive and pain-free as possible!</p>
                {/* <Link to="/"><PrimaryLinkButton text="Learn More"></PrimaryLinkButton></Link> */}
              </GroceryIndustryListRightContent>
            </GroceryIndustryListRight>
          </GroceryIndustryList>
          <GroceryIndustryList>
            <GroceryIndustryListLeft className="industry-left">
              <StaticImage
                src="../images/placholder.jpg"
                quality={100}
                formats={["AUTO", "WEBP", "AVIF"]}
                alt="Industry"
                placeholder="blurred"
              />
            </GroceryIndustryListLeft>
            <GroceryIndustryListRight className="industry-right">
              <GroceryIndustryListRightTitle className="h3">WiFi Networks & Access Points</GroceryIndustryListRightTitle>
              <GroceryIndustryListRightContent>
                <p>WiFi access points are really crucial for modern grocery retail operations, because scan guns and other important devices all connect to your business network wirelessly. In addition, your customers probably expect there to be WiFi availability whenever they’re shopping with you. Whether you need a new wireless network installed, or whether you have an existing network which needs troubleshooting and upgrading, <strong>WYN</strong> has you covered. Plus, we can design and install the right structured cabling and data closet solutions for your business, too.</p>
                {/* <Link to="/"><PrimaryLinkButton text="Learn More"></PrimaryLinkButton></Link> */}
              </GroceryIndustryListRightContent>
            </GroceryIndustryListRight>
          </GroceryIndustryList>
<GroceryIndustryList>
            <GroceryIndustryListLeft className="industry-left">
              <StaticImage
                src="../images/placholder.jpg"
                quality={100}
                formats={["AUTO", "WEBP", "AVIF"]}
                alt="Industry"
                placeholder="blurred"
              />
            </GroceryIndustryListLeft>
            <GroceryIndustryListRight className="industry-right">
              <GroceryIndustryListRightTitle className="h3">Technology Project Management for New Store Rollouts, Remodels, and Location Moves, Adds, & Changes</GroceryIndustryListRightTitle>
              <GroceryIndustryListRightContent>
                <p>We’re happy to provide you with a one-off installation of whatever specialized business technology you may need, but we can do more than that, too. We can serve as your technology installation consultant for any new business site or remodel, as well as for multiple location rollouts. We can de-install any legacy equipment which may need replacing, as well. Whatever the particulars may be for your unique situation, <strong>WYN Technologies</strong> knows how to meet your project deadlines, and even exceed your expectations!</p>
                {/* <Link to="/"><PrimaryLinkButton text="Learn More"></PrimaryLinkButton></Link> */}
              </GroceryIndustryListRightContent>
            </GroceryIndustryListRight>
          </GroceryIndustryList>
          <GroceryIndustryList>
            <GroceryIndustryListLeft className="industry-left">
              <StaticImage
                src="../images/placholder.jpg"
                quality={100}
                formats={["AUTO", "WEBP", "AVIF"]}
                alt="Industry"
                placeholder="blurred"
              />
            </GroceryIndustryListLeft>
            <GroceryIndustryListRight className="industry-right">
              <GroceryIndustryListRightTitle className="h3">IT Support Services</GroceryIndustryListRightTitle>
              <GroceryIndustryListRightContent>
                <p>Wish you had some ongoing business technology IT support for your grocery business? You can, if you partner with <strong>WYN Technologies</strong>. We’re here whenever you need us, and we’re also pleased to offer service-after-the-sale contracts to help keep all your equipment running smoothly for you down the road – that’s one less headache for you to worry about in managing your business!</p>
                {/* <Link to="/"><PrimaryLinkButton text="Learn More"></PrimaryLinkButton></Link> */}
              </GroceryIndustryListRightContent>
            </GroceryIndustryListRight>
          </GroceryIndustryList>
          <GroceryIndustryList>
            <GroceryIndustryListLeft className="industry-left">
              <StaticImage
                src="../images/placholder.jpg"
                quality={100}
                formats={["AUTO", "WEBP", "AVIF"]}
                alt="Industry"
                placeholder="blurred"
              />
            </GroceryIndustryListLeft>
            <GroceryIndustryListRight className="industry-right">
              <GroceryIndustryListRightTitle className="h3">Equipment Staging, Warehousing, & Depot Repair Services</GroceryIndustryListRightTitle>
              <GroceryIndustryListRightContent>
                <p><strong>WYN</strong> has a complete staging facility for getting new equipment set up and ready to go, complete with a secure data closet, video surveillance, and protected access control. Our full-service staging facility allows us to make sure your equipment is configured and ready-to-operate on the date of installation. We also have a large, security-monitored warehouse available for your other equipment storage needs. Got some older equipment that needs replacing? Take advantage of our depot repair services, and benefit from the cost advantage of choosing refurbished equipment over having to purchase a brand-new replacement.</p>
                {/* <Link to="/"><PrimaryLinkButton text="Learn More"></PrimaryLinkButton></Link> */}
              </GroceryIndustryListRightContent>
            </GroceryIndustryListRight>
          </GroceryIndustryList>
        </div>
      </SectionBoxShadow>
      <Section pt="100px" pb="100px" xpt="60px" xpb="60px" mpt="40px" mpb="40px" bgColor="#F8F8F8">
        <div className="container">
          <MarkdownContentWrapper>
            <MarkdownContentWrapperInner>
              <MarkdownContentTitle>
                 {/* <h2 className="h1">Content Heading</h2> */}
              </MarkdownContentTitle>
              <MarkdownContent>
                <h2>VoIP Services for Grocery Stores</h2>
                <p>Having a good business phone and communications system in place is another necessity for grocery enterprises. You may already have an older analog or local PBX system in place, but there are many reasons you should consider upgrading to a VoIP-based phone system. You’ll have access to many features with VoIP that simply aren’t available with older technologies. And the best part? VoIP phone services are generally a good bit cheaper to operate than other standard monthly business phone plans. With more than 20 VoIP provider options to offer, we’re sure to be able to help you find your perfect VoIP solution at <strong>WYN Technologies</strong>. Get in touch with us today, and let us deliver your best business communications solution! </p>
               </MarkdownContent>
              <MarkdownContent>
                <h2>Choose WYN Technologies for All Your Grocery Retail Business Technology Needs</h2>
                <ul>
                  <li>
                    <strong>Tested-and-Approved Custom Solutions for You</strong>
                    <p>At WYN, we’re specially equipped to be able to fully stage, configure, and test your chosen devices and products ahead of time in our dedicated staging facility, before we ever come out to install your new equipment.</p>
                  </li>
                  <li>
                    <strong>Only the Highest-Quality Products & Services </strong>
                    <p>WYN Technologies only partners with the best business technology brands and manufacturers. And our level of installation workmanship and ongoing personalized service for our clients is really second to none!</p>
                  </li>
                  <li>
                    <strong>We Know How to Work Within Your Budget </strong>
                    <p>WYN is ultimately here to help your business succeed. We understand corporate budgets & finances, and we can deliver the best technological solution to meet both your needs and your budget!</p>
                  </li>
                  <li>
                    <strong>Fast, Responsive 24/7 Customer Support</strong>
                    <p>Unlike some other technology providers, WYN is committed to be here for you for the long run. We actually answer the phone when you call, and we’ll respond promptly to your needs 24/7.</p>
                  </li>
                </ul>
              </MarkdownContent>
                 <MarkdownContent>
                <h2>Got Questions? Ready to Get Started?</h2>
                <p>Need to plan some business technology upgrades for an independent grocery store, or for multiple retail locations? <strong>WYN Technologies</strong> is happy to provide you with a free initial site evaluation and consultation. Got more questions? Ready to get started? Connect with us now at <a href="tel:3368990555">(336) 899-0555</a>, and let’s make a plan for your business technology success, together!</p>
              </MarkdownContent>
            </MarkdownContentWrapperInner>
          </MarkdownContentWrapper>
        </div>
      </Section>
    </Layout>
  )
}
export default GroceryPage
